import React from 'react';

import BlogArchive from 'organisms/BlogArchive';


/**
 * Category archive.
 */
export default function CategoryIndex( p ) {

  return(

    <BlogArchive
    type="category"
    { ...p }
    />

  );

}